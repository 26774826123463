// vendors
import React from 'react';
import * as Yup from 'yup';
import '@wldlab/yup-phone';

// components
import Layout from '@components/Layout';

// views
import HeroSection from '@views/ContactFormPageView/HeroSection';
import FormSection from '@views/ContactFormPageView/FormSection';
import SEO from '@src/components/SEO';

const validateSchema = Yup.object().shape({
  profile: Yup.string().required('Sélectionnez votre profile'),
  subject: Yup.string().required('Sélectionnez le sujet de votre de demande'),
  lastName: Yup.string()
    .required('Insérez votre nom')
    .min(2, 'Le nom inséré est trop court (2 caractères min.)')
    .max(50, 'Le nom inséré est trop long (50 caractères max.)'),
  firstName: Yup.string()
    .required('Insérez votre prénom')
    .min(2, 'Le prénom inséré est trop court')
    .max(50, 'Le prénom inséré est trop long'),
  phone: Yup.string()
    .required('Insérez votre numéro de téléphone')
    .phone('CA', 'Insérez un numéro de téléphone valide'),
  email: Yup.string()
    .required('Insérez votre adresse courriel')
    .email('Insérez une adresse courriel valide'),
  message: Yup.string().required('Insérez votre message'),
  url: Yup.string().url('Insérez un lien URL valide'),
});

const FormulaireContactPage = () => {
  const langLinks = [{ langKey: 'en', href: '/en/contact-form' }];

  return (
    <Layout localeLinks={langLinks}>
      <SEO langLinks={langLinks} title='Formulaire de contact' />

      <HeroSection>
        <h1>Formulaire de contact</h1>
      </HeroSection>

      <FormSection validateSchema={validateSchema} />
    </Layout>
  );
};

export default FormulaireContactPage;
